import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';

Bugsnag.start({
  apiKey: '7f6a9ae09fbabc1c21753c4fc5bb26d8',
  plugins: [new BugsnagPluginReact(React)],
});

// Export Bugsnag error boundary component
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
export default ErrorBoundary;
