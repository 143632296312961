import { useState, useEffect } from 'react';
import { getBuName } from '../apis/userApis';

const BusinessUnitName = ({ businessUnitId }) => {
    const [buName, setBuName] = useState('');

    useEffect(() => {
        const fetchBuName = async () => {
            const response = await getBuName({id:businessUnitId});
            if (response) {
                setBuName(response.name);
            }
        };
        fetchBuName();
    }, [businessUnitId]);

    return buName;
};

export default BusinessUnitName;
