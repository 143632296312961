import { Text } from '@mantine/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

const OverDueCompliance=()=> {
  return (
    <div>
      <Text className='font-semibold text-3xl m-4 '>overdue obligations</Text> 
    </div>
  )
}

export default OverDueCompliance