import { getBuList, getBuName } from "../apis/userApis";

export function convertToISO(dateString) {
  const date = new Date(dateString);
  return date.toISOString();
}

export function convertFromISO(isoString) {
  const date = new Date(isoString);
  return date.toString();
}

export function existsAndLength(value) {
  return value?.length > 0;
}

export const formatDateString = (inputDateString) => {
  // Create a Date object from the input date string
  const date = new Date(inputDateString);

  // Function to add leading zero if necessary
  const pad = (number, length = 2) => {
    return String(number).padStart(length, "0");
  };

  // Extract and format each part of the date
  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1); // Months are zero-based
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());
  const milliseconds = pad(date.getMilliseconds(), 3);

  // Construct the formatted date string
  const formattedDateString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.001`;

  return formattedDateString;
};

export const getActFrequencyText = (value) => {
  if (value === 1) {
    return "one-time";
  } else if (value === 2) {
    return "on-going";
  } else if (value === 3) {
    return "reccuring";
  }
};

export const getRecFrequencyText = (value) => {
  if (value === 7) {
    return "weekly";
  } else if (value === 30) {
    return "monthly";
  } else if (value === 90) {
    return "quaterly";
  } else if (value === 365) {
    return "annually";
  }
};

export const getDepartmentText = (value) => {
  if (value === 1) {
    return "Finance";
  } else if (value === 2) {
    return "HR";
  } else if (value === 3) {
    return "IT";
  } else if (value === 4) {
    return "Taxation";
  } else if (value === 5) {
    return "Marketing";
  } else if (value === 6) {
    return "Accessories";
  } else if (value === 7) {
    return "Spares";
  }else if (value === 8){
    return "Operations"
  }
};

export const getDepartmentNumber = (value) => {
  if (value === "Finance") {
    return 1;
  } else if (value === "HR") {
    return 2;
  } else if (value === "IT") {
    return 3;
  } else if (value === "Taxation") {
    return 4;
  } else if (value === "Marketing") {
    return 5;
  } else if (value === "Accessories") {
    return 6;
  } else if (value === "Spares") {
    return 7;
  }else if (value === "Operations"){
    return 8;
  }
};

export const getFutureDate = async (currentDate, daysToAdd) => {
  const date = new Date(currentDate);
  date.setDate(date.getDate() + daysToAdd);

  return date;
};

export const getStatusText = (value) => {
  console.log("value in text: ", value);
  if (value === 0) {
    return "upcoming";
  } else if (value === 1) {
    return "due";
  } else if (value === 2) {
    return "overdue";
  }
  return;
};
export function getDateStatus(createDate, dueDate, reminderDays = 7) {
  const createDateObj = new Date(createDate);
  const dueDateObj = new Date(dueDate);
  const reminderDateObj = new Date(dueDateObj);

  // Adjust reminder date
  reminderDateObj.setDate(dueDateObj.getDate() - reminderDays);

  const currentDate = new Date();

  if ( currentDate < reminderDateObj) {
    console.log("Status: On Time (0)");
    return 0;
  } else if (reminderDateObj <= currentDate && currentDate < dueDateObj) {
    console.log("Status: Due Soon (1)");
    return 1;
  } else if (currentDate >= dueDateObj) {
    console.log("Status: Overdue (2)");
    return 2;
  }

  // return -1; // Unknown status (fallback case)
}



export const getStatusColor = (value) => {
  if (value === 0) {
    return "indigo";
  } else if (value === 1) {
    return "orange";
  } else if (value === 2) {
    return "red";
  }
  return;
};

export const formatDatetoISO = (isoString) => {
  const date = new Date(isoString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const formatDatetoIST = (inputDateString) => {
  const date = new Date(inputDateString + "Z");

  // Convert the date to the desired time zone (IST in this case)
  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZone: "Asia/Kolkata", // This is for GMT+0530 (IST)
    timeZoneName: "short",
  };

  // Format the date to the desired format
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);

  return formattedDate;
};

export const getDays = (inputDate) => {
  const date = new Date(inputDate);
  const today = new Date();
  const differenceInTime = today.getTime() - date.getTime();
  const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
  return differenceInDays;
};

export const getComplianceBadgeColor = (value) => {
  if (value === "compliant") {
    return "indigo";
  } else if (value === "partially-compliant") {
    return "orange";
  } else if (value === "non-compliant") {
    return "red";
  } else if (value === "upcoming"){
    return "blue";
  }
};

export const converDateToISO = (value) => {
  const originalDate = new Date(value);
  const year = originalDate.getFullYear();
  const month = String(originalDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(originalDate.getDate()).padStart(2, "0");
  const hours = String(originalDate.getHours()).padStart(2, "0");
  const minutes = String(originalDate.getMinutes()).padStart(2, "0");
  const seconds = String(originalDate.getSeconds()).padStart(2, "0");
  const milliseconds = String(originalDate.getMilliseconds()).padStart(3, "0");

  // Formatted date and time
  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;

  return formattedDate;
};

export const convertToISO8601 = (dateString) => {
  // Split the date and time parts
  let [datePart, timePart] = dateString.split(" ");

  // Combine date and time with 'T' and append 'Z'
  let iso8601String = `${datePart}T${timePart}Z`;

  return iso8601String;
};

// export async function buData(params) {
//   const response  = await getBuList()
// }

export function generateRandomColors(numColors) {
  const colors = [];
  for (let i = 0; i < numColors; i++) {
    colors.push(
      `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(
        Math.random() * 255
      )}, ${Math.floor(Math.random() * 255)}, 0.7)`
    );
  }
  return colors;
}

export const infoColor = (value) => {
  switch (value) {
    case "1":
      return "red"; // Or any other color you want
    case "2":
      return "orange";
    case "3":
      return "green";
    default:
      return "gray"; // Default color if none of the values match
  }
};
export const infoColorTable = (value) => {
  switch (value) {
    case "1":
      return "#ff6242"; // Or any other color you want
    case "2":
      return "#feac56";
    case "3":
      return "#aef359";
    default:
      return "white"; // Default color if none of the values match
  }
};

export const csvDownload = ({ headers, data, name }) => {
  let csvContent = `data:text/csv;charset=utf-8,`;
  csvContent += headers.join(",") + "\n";

  data.forEach((item) => {
    headers.forEach((header) => {
      csvContent += `${item[header]},`;
    });
    csvContent += "\n";
  });

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `${name}.csv`);
  document.body.appendChild(link);
  link.click();
};

export function formatDate(dateStr) {
  const dateUTC = new Date(dateStr);

  // Step 2: Convert to IST by adjusting the timezone offset (IST is UTC + 5:30)
  const offsetIST = 5 * 60 + 30; // 5 hours and 30 minutes in minutes
  const dateIST = new Date(dateUTC.getTime() + offsetIST * 60 * 1000);

  // Extract the date and time components in IST
  const istDate = dateIST.toLocaleDateString("en-IN"); // Format date according to Indian locale
  const istTime = dateIST.toLocaleTimeString("en-IN", { hour12: false });
  return istDate;
}

export function formatTime(dateStr) {
  const dateUTC = new Date(dateStr);

  // Step 2: Convert to IST by adjusting the timezone offset (IST is UTC + 5:30)
  const offsetIST = 5 * 60 + 30; // 5 hours and 30 minutes in minutes
  const dateIST = new Date(dateUTC.getTime() + offsetIST * 60 * 1000);

  // Extract the date and time components in IST
  const istDate = dateIST.toLocaleDateString("en-IN"); // Format date according to Indian locale
  const istTime = dateIST.toLocaleTimeString("en-IN", { hour12: false });
  return istTime;
}

export function getNotificationColor(userId){
  if (userId==="1") {
    return "#46bde1";
  }
  else if (userId==="2") {
    return "#ffa94d";
  } else if (userId==="3"){
    return "#fa5252";
  }
   else if (userId==="4") {
    return "#40c057";
  }else {
    return "#46bde1";
  }
}



export const mapRoleToLabel = (role) => {
  switch (role) {
    case "Ob-Admin":
      return "system-admin";
    case "Ob-Editor":
      return "editor";
    case "Ob-DeptAdmin":
      return "dept-admin";
    case "Ag-Editor":
      return "ag-editor";
    case "Ob-CEO":
    return "CEO";
    case "Ob-COO":
    return "COO";
    case "Ob-CXO":
    return "CXO"; 
    case "Ob-DeptUser":
    return "dept-user"
    case "Ob-Auditor":
    return "auditor"
    default:
      return role; // Fallback to original role if no mapping is found
  }
};