import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { generateRandomColors, getDepartmentNumber } from "../pages/utilities";
ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart3 = ({
  filtersForData,
  setFiltersForData,
  labels,
  datafor,
  departmentData,
}) => {
  const data = {
    labels: labels,
    datasets: [
      {
        label: "obligations",
        data: datafor,
        backgroundColor: generateRandomColors(departmentData.length),
        // backgroundColor: [
        //   "rgba(255, 206, 86, 0.6)",
        //   "rgba(255, 99, 132, 0.6)",
        //   "rgba(145, 79, 32, 0.6)",
        //   "rgba(54, 162, 235, 0.6)",
        // ],
        // hoverBackgroundColor: [
        //   "rgba(255, 206, 86, 0.8)",
        //   "rgba(255, 99, 132, 0.8)",
        //   "rgba(145, 79, 32, 0.8)",
        //   "rgba(54, 162, 235, 0.8)",
        // ],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const elementIndex = elements[0].index;
        const label = data.labels[elementIndex].split("/")[0];
        setFiltersForData({
          ...filtersForData,
          department: getDepartmentNumber(label),
        });
      }
    },
    plugins: {
      legend: {
        display: true,
        position: "right",
      },
    },
  };

  return (
    <div style={{height: "200px", width: "100%" }}>
      <Doughnut data={data} options={options} />
      </div>
  );
};

export default DoughnutChart3;
