import React, { useEffect, useState } from "react";
import { getauditHistory } from "../apis/userApis";
import { Table } from "@mantine/core";
import { useDispatch, useSelector } from 'react-redux';

const ObligationTranHistoryModal = ({ id }) => {
  const [configs, setConfigs] = useState({
    loading: true,
    history: [],
  });
  useEffect(() => {
    const getHistory = async () => {
      const response = await getauditHistory({ id });
      console.log("history: ", response);
      setConfigs({loading: false, history: response.auditHistory})
    };
    getHistory();
  }, [id]);

  let sr =1;
  
  return (
    <div>
        {!(configs.loading) && configs.history.length > 0 ?
      <Table striped>
        <thead className="text-center">
          <tr>
            <th style={{ textAlign: "center" }}>action id</th>
            <th style={{ textAlign: "center" }}>actioned by</th>
            <th style={{ textAlign: "center" }}>actioned at</th>
            <th style={{ textAlign: "center" }}>status</th>
            <th style={{ textAlign: "center" }}>remarks</th>
          </tr>
        </thead>
        <tbody className="text-center">
            {configs.history.map((row)=>
          <tr key={row.id}>
            <td>{sr++}</td>
            <td>{row.doneby}</td>
            <td>{(row.attime).split('T')[0]}</td>
            <td>{row.status}</td>
            <td>{row.extraCol1}</td>
          </tr>
            )}
        </tbody>
      </Table>
      :
      <h1>no action taken on this obligation yet</h1>
}
    </div>
  );
};

export default ObligationTranHistoryModal;
