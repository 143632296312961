import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import SideNav from "./pages/SideNav";
import AssignedObligations from "./pages/AssignedObligations";
import CompletedObligation from "./pages/CompletedObligation";
import ViewObligation from "./pages/ViewObligation";
import CreateNewObligation from "./pages/CreateNewObligation";
import DocsLocker from "./pages/DocsLocker";
import ViewAgreement from "./pages/ViewAgreement";
import "./App.css";
import TeamManager from "./pages/TeamManager";
import ViewBu from "./pages/ViewBu";
import DashboardCompliance from "./pages/DashboardCompliance";
import OverDueCompliance from "./pages/OverDueCompliance";
import Notifications from "./pages/Notifications";
import ViewMasterObligation from "./pages/ViewMasterObligation";
import ActionedObligations from "./pages/ActionedObligations";
import Acceptobligation from "./pages/AcceptObligation";
import Cookies from "js-cookie";
import { getUserDetails } from "./apis/userApis";
import MyDocuments from "./pages/MyDocuments";

const App = () => {
  const [config, setConfig] = useState({
    Loading: true,
  });
  
  const getCookiesSession = (cookieName) => Cookies.get(cookieName);
  let user = null;
  if (getCookiesSession("trueCounselUserData")) {
    user = JSON.parse(getCookiesSession("trueCounselUserData")).user;
  }
  
  // useEffect only depends on user and config.Loading, not user.email directly
  useEffect(() => {
    const getuser = async () => {
      if (user && config.Loading) {
        console.log("user in useEffect: ", user);
        const response = await getUserDetails({ email: user.email });  
        console.log("getuserdata: ", response);
        if (response.success && response.user) {
          const userDatafromtoken = {
            ...response.user,
            loginAs: response.user.role.split(",")[0],
          };
          localStorage.setItem("userData", JSON.stringify(userDatafromtoken));
          setConfig({ Loading: false });
        } else{
          setConfig({ Loading: false });
        }
      } else if (!user ) {
        // if no user data in cookies, set loading to false
        setConfig({ Loading: false });
      }
    };
  
    getuser();
  }, [user, config.Loading]);
  
  const userData = JSON.parse(localStorage.getItem("userData"));
  console.log("userDatafromlocal: ", userData);
  
 
  return (
    <>
    {!config.Loading &&
    <div className="app">
    <BrowserRouter>
      <Routes>
        {/* Redirect to dashboard if userData exists, otherwise redirect to login */}
        <Route
          path="/"
          element={
            userData ? <Navigate to="/dashboard" /> : <Navigate to="/login" />
          }
        />

        {/* Public route for login */}
        {!userData && <Route path="/login" element={<Login />} />}

        {/* Protected routes, only accessible if userData exists */}
        {userData && (
          <>
            <Route path="/login" element={<Navigate to="/dashboard" />} />
            <Route
              path="/assigned"
              element={<SideNav appContent={<AssignedObligations />} />}
            />
            <Route
              path="/acceptobligation"
              element={<SideNav appContent={<Acceptobligation />} />}
            />
            <Route
              path="/documents"
              element={<SideNav appContent={<MyDocuments />} />}
            />
            <Route
              path="/obligationtracker"
              element={<SideNav appContent={<CompletedObligation />} />}
            />
            <Route
              path="/dashboard"
              element={<SideNav appContent={<DashboardCompliance />} />}
            />
            <Route
              path="/overdue"
              element={<SideNav appContent={<OverDueCompliance />} />}
            />
            <Route
              path="/viewobligation/:id"
              element={<SideNav appContent={<ViewObligation />} />}
            />
            <Route
              path="/new"
              element={<SideNav appContent={<CreateNewObligation />} />}
            />
            <Route
              path="/docslocker"
              element={<SideNav appContent={<DocsLocker />} />}
            />
            <Route
              path="/viewagreement/:id"
              element={<SideNav appContent={<ViewAgreement />} />}
            />
            <Route
              path="/viewbu/:id"
              element={<SideNav appContent={<ViewBu />} />}
            />
            <Route
              path="/viewmasterobligation/:id"
              element={<SideNav appContent={<ViewMasterObligation />} />}
            />
            <Route
              path="/team"
              element={<SideNav appContent={<TeamManager />} />}
            />
            <Route
              path="/notifications"
              element={<SideNav appContent={<Notifications />} />}
            />
            <Route
              path="/actioned"
              element={<SideNav appContent={<ActionedObligations />} />}
            />
          </>
        )}

        {/* Redirect all other paths to login if userData is not present */}
        {!userData && <Route path="*" element={<Navigate to="/login" />} />}
      </Routes>
    </BrowserRouter>
  </div>
    }

    </>
    
  );
};

export default App;
