import React, { useEffect, useState } from "react";
import { getNamesofAgreement, getSignedAgreementList, getTitlesOfAgreement } from "../apis/userApis";
import { Button, Pagination, Select, Table, TextInput } from "@mantine/core";
import { useDispatch, useSelector } from 'react-redux';

const SignedAgreementListModal = ({
  setSelectedAgreementData,
  setSelectedAgreementId,
  setShowModal,
}) => {
  const [configs, setConfigs] = useState({
    agreementsList: [],
    loading: true,
  });
  const [applyFilters, setApplyFilters] = useState(false);
  const [filters, setFilters] = useState({
    location: null,
    titleOfAgreement: null,
    businessUnit: null,
    typeOfAgreement: null,
    OR: [
      { secondParty: { path: "$.name", string_contains: "" } },
        {
          secondParty: {
            path: "$.indi_name",
            string_contains: "",
          },
        },
    ],
  });
  const [categoryOptions, setCategoryOptions] = useState([
    "NDA",
    "Service Agreement",
  ]);
  const [nameOptions, setNameOptions] = useState([
    "truecounsel NDA",
    "tc services",
  ]);

  useEffect(() => {
    const getTitles = async () => {
      const response = await getTitlesOfAgreement();
      if (response.success) {
        setNameOptions(response.titlesOfAgreement);
      }
    };
    const getNames = async () => {
      const response = await getNamesofAgreement();
      if (response.success) {
        setCategoryOptions(response.namesOfAgreement);
      }
    };
    getTitles();
    getNames();
  }, []);
  const [page, setPage] = useState(1);
  const [count , setCount] = useState(0);
  
      // let filtersForData = Object.fromEntries(
      //   Object.entries(filters).filter(([key, value]) => value !== null)
      // );
      
  //     console.log("filtersForData: ", filtersForData);
      
  //     // Check if "OR" exists and has the expected structure before accessing its values
      // if (
      //   filtersForData.OR &&
      //   filtersForData.OR[0] &&
      //   filtersForData.OR[0].secondParty &&
      //   filtersForData.OR[0].secondParty.string_contains === ""
      // ) {
      //   filtersForData = Object.fromEntries(
      //     Object.entries(filtersForData).filter(([key]) => key !== "OR")
      //   );
      // }
      
  //     console.log("Updated filtersForData: ", filtersForData);
  //     console.log("filtersForData2: ", filtersForData);
  //     const response = await getLocalAgreements({
  //       page,
  //       filters: filtersForData,
  //     });
  //     if (response.success) {
  //       setConfigs({
  //         loading: false,
  //         agreements: response.agreements,
  //         count: response["count"],
  //       });
  //       console.log(configs);
  //     } else {
  //       setConfigs({
  //         loading: false,
  //         agreements: null,
  //         count: 0,
  //       });
  //     }
  //   };
  //   getAgreements();
  // }, [page, applyFilters]);

  useEffect(() => {
    const signedlist = async () => {
      let filtersForData = Object.fromEntries(
        Object.entries(filters).filter(([key, value]) => value !== null)
      );
      if (
        filtersForData.OR &&
        filtersForData.OR[0] &&
        filtersForData.OR[0].secondParty &&
        filtersForData.OR[0].secondParty.string_contains === ""
      ) {
        filtersForData = Object.fromEntries(
          Object.entries(filtersForData).filter(([key]) => key !== "OR")
        );
      }
      const SignedagreementList = await getSignedAgreementList({
        page: page,
        filters: filtersForData,
      });
      console.log(SignedagreementList.agreementsList);
      setConfigs({
        agreementsList: SignedagreementList.agreementsList,
        loading: false,
      });
      setCount( SignedagreementList.count);
    };
    signedlist();
  }, [page, applyFilters]);

  const renderTableRows = () => {
    if (configs.agreementsList.length === 0) return null;
    const startIndex = (page - 1) * 10;
    const endIndex = Math.min(startIndex + 10, configs.agreementsList.length);
    const rows = [];

    // console.log("agreementId: ", connectedAgreementId);
    for (let i = startIndex; i < endIndex; i++) {
      const agreement = configs.agreementsList[i];
      rows.push(
        <tr key={agreement.id}>
          <td>
            <input
                onChange={(e) => {
                  e.preventDefault();
                  setSelectedAgreementId(agreement.id);
                  setSelectedAgreementData(agreement);
                  setShowModal(false);
                }}
              type="checkbox"
            />
          </td>
          <td>{agreement.id}</td>
          <td>{agreement.typeOfAgreement}</td>
          <td>{agreement.location}</td>
          <td>{agreement.status}</td>
          <td>{agreement.secondParty.name}</td>
          <td>{agreement.dueDateOfContract}</td>
        </tr>
      );
    }

    return rows;
  };

  return (
    <>
      {!configs.loading && configs.agreementsList.length > 0 && (
        <div
          className="flex flex-col"
          style={{
            overflowX: "scroll",
            scrollbarWidth: "none",
            width: "100%",
          }}
        >
          <div className="grid grid-cols-6 gap-4 m-4">
        <Select
          label="agreement type"
          placeholder="agreement type"
          value={filters.typeOfAgreement}
          data={categoryOptions}
          onChange={(val) => setFilters({ ...filters, typeOfAgreement: val })}
        />
        <Select
          placeholder="agreement title"
          label="title"
          value={filters.titleOfAgreement}
          data={nameOptions}
          onChange={(val) => setFilters({ ...filters, titleOfAgreement: val })}
        />
        {/* {buData !== null && (
          <Select
            label="business Unit"
            placeholder="select business Unit"
            data={buData}
            onChange={(val) => setFilters({ ...filters, businessUnit: val })}
            value={filters.businessUnit}
          />
        )} */}
        <TextInput
          label="second party"
          type="text"
          placeholder="type to search..."
          value={filters.OR[0].secondParty.string_contains}
          onChange={(e) => {
            // const value = e.target.value;

            setFilters((prevFilters) => ({
              ...prevFilters, // Preserve the existing filters
              OR: [
                { secondParty: { path: "$.name", string_contains: e.target.value } },
                {
                  secondParty: {
                    path: "$.indi_name",
                    string_contains: e.target.value,
                  },
                },
              ],
            }));
          }}
        />

        {/* <Select
          placeholder="location"
          label="location"
          value={filters.location}
          data={INDIA_STATES_UNIONS}
          onChange={(val) => setFilters({ ...filters, location: val })}
        /> */}
      </div>
      <div className="m-4">
        <Button
          onClick={() => setApplyFilters(!applyFilters)}
          className="mr-4"
          color="green"
        >
          search
        </Button>
        <Button
          onClick={() => {
            setFilters({
              location: null,
              titleOfAgreement: null,
              businessUnit: null,
              typeOfAgreement: null,
              OR: [
                { secondParty: { path: "$.name", string_contains: "" } },
                  {
                    secondParty: {
                      path: "$.indi_name",
                      string_contains: "",
                    },
                  },
              ],
            });
            setApplyFilters(!applyFilters);
          }}
          color="red"
        >
          clear
        </Button>
      </div>
          <Table striped>
            <thead>
              <tr>
                <th>Select</th>
                <th>Reference No.</th>
                <th>Type</th>
                <th>Location</th>
                <th>Status</th>
                <th>Second Party</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody id="tablebody">
              {configs.agreementsList.map(agreement=>
            <tr key={agreement.id}>
          <td>
            <input
                onChange={(e) => {
                  e.preventDefault();
                  setSelectedAgreementId(agreement.id);
                  setSelectedAgreementData(agreement);
                  setShowModal(false);
                }}
              type="checkbox"
            />
          </td>
          <td>{agreement.id}</td>
          <td>{agreement.typeOfAgreement}</td>
          <td>{agreement.location}</td>
          <td>{agreement.status}</td>
          <td>{agreement.secondParty.name}</td>
          <td>{agreement.dueDateOfContract}</td>
        </tr>
      )}
            </tbody>
          </Table>
        </div>
      )}
      {!configs.loading && configs.agreementsList.length > 0 && (
        <div className="flex flex-row justify-center my-4">
          <Pagination
            page={page}
            onChange={(page) => {
              setPage(page);
            }}
            total={Math.ceil(count / 10)}
          />
        </div>
      )}
    </>
  );
};

export default SignedAgreementListModal;
