import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({ filtersForData, setFiltersForData, labels, datafor }) => {
  const data = {
    labels: labels,
    datasets: [
      {
        label: "obligations",
        data: datafor,
        backgroundColor: [
          "rgba(255, 206, 86, 0.8)",
          "rgba(255, 99, 132, 0.8)",
          "rgba(54, 162, 235, 0.8)",
          "lightblue"
        ],
        hoverBackgroundColor: [
          "rgba(255, 206, 86, 1)",
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "lightblue"
        ],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const elementIndex = elements[0].index;
        const label = data.labels[elementIndex].split("/")[0];
        setFiltersForData({ ...filtersForData, compliant: label });
      }
    },
    plugins: {
      legend: {
        display: true,
        position: "right",
      },
    },
  };

  return (
    <div style={{ height: "200px", width: "100%" }}>
      <Doughnut data={data} options={options} />
    </div>
  );
};

export default DoughnutChart;

