import React, { useState, useEffect } from "react";
import { getAssignedObligations } from "../apis/userApis";
import {
  ActionIcon,
  Badge,
  Button,
  Modal,
  Pagination,
  Select,
  Table,
  Text,
} from "@mantine/core";
import { IconEye, IconInfoCircle } from "@tabler/icons-react";
import { IconHistory } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import ObligationTranHistoryModal from "./ObligationTranHistoryModal";
import {
  formatDatetoISO,
  getStatusColor,
  getStatusText,
  infoColor,
} from "./utilities";
import lexend from "./Lexend-Black.ttf";
import { useDispatch, useSelector } from "react-redux";
import { selectUserData } from "../redux/authSlice";
import BulkUploadProofs from "../componenets/BulkUploadProofs";

const AssignedObligations = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [ahModal, setAhModal] = useState(false);
  const [ahId, setAhId] = useState(0);
  const businessUnit = userData.businessUnit;
  const department = userData.department;
  const [configs, setConfigs] = useState({
    loading: true,
    obligations: [],
    count: 0,
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedObligations, setSelectedObligations] = useState([]);
  const [uploadModal, setUploadModal] = useState(false);

  // Function to handle the selection change
  const handleSelect = (row) => {
    setSelectedObligations((prevSelected) => {
      if (prevSelected.some((item) => item.id === row.id)) {
        // If already selected, remove it
        return prevSelected.filter((item) => item.id !== row.id);
      } else {
        // If not selected, add it
        return [...prevSelected, row];
      }
    });
  };
  const [reload, setReload] = useState(true);
  useEffect(() => {
    const getObligations = async () => {
      const response = await getAssignedObligations({
        department: department,
        businessUnit: businessUnit,
        page,
        pageSize
      });
      console.log(response.obligations);
      setConfigs({
        loading: false,
        obligations: response.obligations,
        count: response.count,
      });
    };
    getObligations();
  }, [reload, page, pageSize]);

  const onViewClick = (id) => {
    navigate(`/viewobligation/${id}`);
  };
  console.log("selectedobligations: ", selectedObligations);
  return (
    <div style={{ fontFamily: lexend }}>
      <Text className="flex justify-center font-bold text-xl my-4">
        assigned obligations
      </Text>
      <div className="flex justify-end">
        {selectedObligations.length > 0 && (
          <Button 
          className=" my-4"
          onClick={()=> setUploadModal(true)}
          >upload proofs</Button>
        )}
      </div>
      {!configs.loading && configs.obligations?.length > 0 && (
        <>
          <Table striped>
            <thead className="text-center">
              <tr>
                <th style={{ textAlign: "center" }}>select</th>
                <th style={{ textAlign: "center" }}>obligation id</th>
                <th style={{ textAlign: "center" }}>obligation title</th>
                <th style={{ textAlign: "center" }}>second party</th>
                <th style={{ textAlign: "center" }}>due date</th>
                <th style={{ textAlign: "center" }}>status</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {!configs.loading &&
                configs.obligations?.length > 0 &&
                configs.obligations
                  
                  .map((row) => {
                    return (
                      <tr key={row.id}>
                        <td>
                          <input
                            type="checkbox"
                            onChange={() => handleSelect(row)}
                            // Set the checkbox to checked if the row is selected
                            checked={selectedObligations.some(
                              (item) => item.id === row.id
                            )}
                          />
                        </td>
                        <td>{row.id}</td>
                        <td>{row.title}</td>
                        <td>{row.connectedParty}</td>
                        <td>{formatDatetoISO(row.duedate)}</td>
                        <td>
                          <Badge
                            variant="light"
                            color={getStatusColor(row.status)}
                          >
                            {getStatusText(row.status)}
                          </Badge>
                        </td>

                        <td>
                          <div className="flex flex-row mr-4">
                            <ActionIcon
                              onClick={() => {
                                onViewClick(row.id);
                              }}
                              color="white"
                            >
                              <IconEye size={24} />
                            </ActionIcon>
                            {/* )} */}
                          </div>
                        </td>
                        <td>
                          <div className="flex flex-row mr-4">
                            <ActionIcon
                              onClick={() => {
                                setAhId(row.id);
                                setAhModal(true);
                              }}
                              color="white"
                            >
                              <IconHistory size={24} />
                            </ActionIcon>

                            {/* )} */}
                          </div>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <div className="flex flex-row mr-4">
                            {row.extraCol3 !== "" && (
                              <ActionIcon color="white">
                                <IconInfoCircle
                                  color={infoColor(row.extraCol3)}
                                  size={24}
                                />
                              </ActionIcon>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </Table>
          <div className="flex flex-row justify-center my-4 gap-4">
            <Pagination
              color="#46BDE1"
              page={page}
              onChange={(page) => {
                setPage(page);
              }}
              total={Math.ceil(configs.count / pageSize)}
            />
            <Select
                style={{ width: "70px" }}
                value={pageSize + ""}
                data={["10", "20", "50"]}
                onChange={(val) =>
                  setPageSize(parseInt(val) )
                }
              />
          </div>
        </>
      )}
      <Modal
        opened={ahModal}
        onClose={() => {
          setAhModal(false);
          setAhId(0);
        }}
        title="oblligation action history"
        size="calc(80vw)"
      >
        <ObligationTranHistoryModal id={ahId} />
      </Modal>
      <Modal
        opened={uploadModal}
        onClose={() => {
          setUploadModal(false);
        }}
        title="bulk upload of proofs"
        size="calc(80vw)"
      >
        <BulkUploadProofs setReload={setReload} reload={reload} setUploadModal={setUploadModal} obligations={selectedObligations} />
      </Modal>
    </div>
  );
};

export default AssignedObligations;
