import {
  ActionIcon,
  Badge,
  Button,
  Modal,
  Pagination,
  Select,
  Table,
  Text,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { getComplianceStatus } from "../apis/userApis";
import BusinessUnitName from "../componenets/getBuName";
import {
  getDepartmentText,
  getComplianceBadgeColor,
  infoColor,
  getDateStatus,
  getStatusColor,
  getStatusText,
  csvDownload,
} from "./utilities";
import { IconEye, IconHistory, IconRefresh } from "@tabler/icons-react";
import DoughnutChart from "../componenets/DoughnutChart";
import DoughnutChart3 from "../componenets/DoughnutChart3";
import DoughnutChart2 from "../componenets/DoughnutChart2";
import DoughnutChart4 from "../componenets/DoughnutChart4";
import { useNavigate } from "react-router-dom";
import ObligationTranHistoryModal from "./ObligationTranHistoryModal";
import { IconInfoCircle } from "@tabler/icons-react";

const DashboardCompliance = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [uiConfig, setUiConfig] = useState({
    obligations: null,
    loading: true,
  });
  const [countConfig, setCountConifg] = useState({
    page: 1,
    count: 0,
    pageSize: 10,
  });
  const [filtersForData, setFiltersForData] = useState({
    compliant: null,
    department:
      userData.role === "Ob-CXO" || userData.role === "Ob-DeptAdmin"
        ? userData.department
        : null,
    businessUnit: userData.role === "Ob-COO" ? userData.businessUnit : null,
    extraCol1: null,
  });
  const [csvData, setCsvData] = useState([]);
  const [compliantData, setCompliantData] = useState(null);
  const [businessUnitData, setBusinessUnitData] = useState(null);
  const [departmentData, setDepartmentData] = useState(null);
  const [agreementData, setAgreementData] = useState(null);
  const [ahModal, setAhModal] = useState(false);
  const [ahId, setAhId] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      const filterdata = Object.fromEntries(
        Object.entries(filtersForData).filter(([key, value]) => value !== null)
      );
      const response = await getComplianceStatus({
        filters: filterdata,
        page: countConfig.page,
        pageSize:countConfig.pageSize
      });
      console.log(response);
      setUiConfig({
        loading: false,
        obligations: response.obligations,
      });
      setCountConifg({
        ...countConfig,
        count: response.count,
      });
      setCompliantData(response.compliantStatusCount);
      setBusinessUnitData(response.businessUnitCount);
      setDepartmentData(response.departmentCount);
      setAgreementData(response.agreementCount);
      setCsvData(response.exportData);
    };
    getData();
  }, [countConfig.page, filtersForData, countConfig.pageSize]);
  let sr = (countConfig.page - 1) * countConfig.pageSize + 1;
  const onViewClick = (id) => {
    navigate(`/viewobligation/${id}`);
  };

  const onExport = async () => {
    if (csvData.length > 0) {
      const columnHeaders = Object.keys(csvData[0]);
      csvDownload({
        data: csvData,
        headers: columnHeaders,
        name: "dashboard_obligations",
      });
    }
  };
  const [expandedId, setExpandedId] = useState(null); // Tracks the currently expanded obligation by ID

  const handleExpand = (id) => {
    setExpandedId(id); // Set the ID of the obligation to expand
  };
  return (
    <>
      <div className="m-4">
        <div className="mb-6 flex justify-between">
          <Text className=" text-3xl font-semibold">compliance status</Text>
          <div className="flex justify-center">
            <ActionIcon
              className="my-auto"
              onClick={() =>
                setFiltersForData({
                  compliant: null,
                  department:
                    userData.role === "Ob-CXO" ||
                    userData.role === "Ob-DeptAdmin"
                      ? userData.department
                      : null,
                  businessUnit:
                    userData.role === "Ob-COO" ? userData.businessUnit : null,
                  connectedAgreement: null,
                })
              }
            >
              <IconRefresh size={30} />
            </ActionIcon>
            <Button
              className="ml-4 text-center btn p-2"
              style={{ backgroundColor: "#46bde1" }}
              onClick={onExport}
            >
              export
            </Button>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          {compliantData !== null && (
            <div className="chart-container">
              <Text className="font-medium block text-center">compliance</Text>
              <div className="flex justify-center p-4">
                <DoughnutChart
                  compliantData={compliantData}
                  filtersForData={filtersForData}
                  setFiltersForData={setFiltersForData}
                  labels={compliantData.map(
                    (item) => `${item.compliant}/${item._count.id}`
                  )}
                  datafor={compliantData.map((item) => item._count.id)}
                />
              </div>
            </div>
          )}
          {businessUnitData !== null && (
            <div className="chart-container">
              <Text className="font-medium block text-center">
                business unit
              </Text>
              <div className="flex justify-center p-4">
                <DoughnutChart2
                  businessUnitData={businessUnitData}
                  filtersForData={filtersForData}
                  setFiltersForData={setFiltersForData}
                  labels={businessUnitData.map(
                    (item) => `${item.name}/${item._count.id}`
                  )}
                  datafor={businessUnitData.map((item) => item._count.id)}
                />
              </div>
            </div>
          )}
          {departmentData !== null && (
            <div className="chart-container">
              <Text className="font-medium block text-center">department</Text>
              <div className="flex justify-center p-4">
                <DoughnutChart3
                  departmentData={departmentData}
                  filtersForData={filtersForData}
                  setFiltersForData={setFiltersForData}
                  labels={departmentData.map(
                    (item) =>
                      `${getDepartmentText(item.department)}/${item._count.id}`
                  )}
                  datafor={departmentData.map((item) => item._count.id)}
                />
              </div>
            </div>
          )}
          {agreementData !== null && (
            <div className="chart-container">
              <Text className="font-medium block text-center">
                connected agreement
              </Text>
              <div className="flex justify-center p-4">
                <DoughnutChart4
                  agreementData={agreementData}
                  filtersForData={filtersForData}
                  setFiltersForData={setFiltersForData}
                  labels={agreementData.map(
                    (item) => `${item.extraCol1 + ""}/${item._count.id}`
                  )}
                  datafor={agreementData.map((item) => item._count.id)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {uiConfig.obligations !== null && (
        <>
          <Table striped>
            <thead className="text-center">
              <tr>
                <th style={{ textAlign: "center" }}>sr. no.</th>
                <th style={{ textAlign: "center" }}>title</th>
                <th style={{ textAlign: "center" }}>obligation</th>
                <th style={{ textAlign: "center" }}>agreement type</th>
                <th style={{ textAlign: "center" }}>business unit</th>
                <th style={{ textAlign: "center" }}>second party</th>
                <th style={{ textAlign: "center" }}>department</th>
                <th style={{ textAlign: "center" }}>compliance</th>
                <th style={{ textAlign: "center" }}>status</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {uiConfig.obligations.map((obligation) => (
                <tr key={obligation.id} style={{ textAlign: "center" }}>
                  <td>{sr++}</td>
                  <td>{obligation.title}</td>
                  <td style={{ textAlign: "center" }}>
                      {expandedId === obligation.id
                        ? obligation.desc // Show full description if expanded
                        : obligation.desc.slice(0, 50)}
                      {/* Truncated description */}
                      {expandedId !== obligation.id && obligation.desc.length>50 &&  (
                        <span
                          onClick={() => handleExpand(obligation.id)} 
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          ...
                        </span>
                      )}
                    </td>
                  <td>{obligation.extraCol1}</td>
                  <td>{obligation.businessUnit}</td>
                  <td>{obligation.connectedParty}</td>
                  <td>{getDepartmentText(obligation.department)}</td>
                  <td>
                    <Badge
                      variant="light"
                      color={getComplianceBadgeColor(obligation.compliant)}
                    >
                      {obligation.compliant}
                    </Badge>
                  </td>
                  <td>
                    <Badge
                      variant="light"
                      color={getStatusColor(
                        getDateStatus(
                          obligation.createdAt,
                          obligation.duedate,
                          obligation.reminderTime
                        )
                      )}
                    >
                      {getStatusText(
                        getDateStatus(
                          obligation.createdAt,
                          obligation.duedate,
                          obligation.reminderTime
                        )
                      )}
                    </Badge>
                  </td>
                  <td>
                    <div className="flex flex-row mr-4">
                      <ActionIcon
                        onClick={() => {
                          onViewClick(obligation.id);
                        }}
                        color="white"
                      >
                        <IconEye size={24} />
                      </ActionIcon>
                      {/* )} */}
                    </div>
                  </td>
                  <td>
                    <div className="flex flex-row mr-4">
                      <ActionIcon
                        onClick={() => {
                          setAhId(obligation.id);
                          setAhModal(true);
                        }}
                        color="white"
                      >
                        <IconHistory size={24} />
                      </ActionIcon>

                      {/* )} */}
                    </div>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <div className="flex flex-row mr-4">
                      {obligation.extraCol3 !== "" && (
                        <ActionIcon color="white">
                          <IconInfoCircle
                            color={infoColor(obligation.extraCol3)}
                            size={24}
                          />
                        </ActionIcon>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="flex flex-row justify-center my-4 gap-4">
            <Pagination
              page={countConfig.page}
              onChange={(val) => setCountConifg({ ...countConfig, page: val })}
              total={Math.ceil(countConfig.count / countConfig.pageSize)}
            />
            <Select
            style={{width: "70px"}}
            value={countConfig.pageSize+ ""}
            data={[
              "10", "20", "50"
            ]}
            onChange={(val)=> setCountConifg({...countConfig, pageSize: parseInt(val)})}
            />
          </div>
        </>
      )}
      <Modal
        opened={ahModal}
        onClose={() => {
          setAhModal(false);
          setAhId(0);
        }}
        title="oblligation action history"
        size="calc(80vw)"
      >
        <ObligationTranHistoryModal id={ahId} />
      </Modal>
    </>
  );
};

export default DashboardCompliance;
