// userSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userData: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    logout: (state) => {
      state.userData = null;
    },
    changeLoginAs: (state, action) => {
      if (state.userData) {
        state.userData.loginAs = action.payload;
      }
    },
  },
});

export const { setUserData, logout, changeLoginAs } = userSlice.actions;
export const selectUserData = (state) => state.user.userData;
export default userSlice.reducer;
