import { ActionIcon, Badge, Button, Modal, Pagination, Table, Text } from "@mantine/core";
import React, { useEffect, useState } from "react";
import AddBuModal from "./AddBuModal";
import AddUserModal from "./AddUserModal";
import { getBuList } from "../apis/userApis";
import { IconEdit, IconEye } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

const TeamManager = () => {
  const navigate = useNavigate();
  const [buModal, setBuModal] = useState(false);
  const [count, setCount]= useState(0);
  const [page, setPage]= useState(1);
  const [userModal, setUserModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [editBuId, setEditBuId] = useState(0);
  const [list, setList] = useState(null);
  useEffect(() => {
    const bulist = async () => {
      const response = await getBuList({page});
      if (response.success) {
        setList(response.buList);
        setCount(response.count);
        setLoading(false);
      } else if (!response.success) {
        setLoading(false);
        alert("error fetching business unit list");
      }
    };
    bulist();
  }, [page]);
  console.log(list);
  return (
    <div>
      {/* <Text className="m-4 text-xl font-semibold">team manager</Text> */}
      <Modal
        title="add business unit"
        opened={buModal}
        onClose={() =>{ setEditBuId(0); setBuModal(false)}}
      >
        <AddBuModal setEditBuId={setEditBuId} editBuId={editBuId} setBuModal={setBuModal} />
      </Modal>

      <div className="flex justify-between m-4">
        <Button style={{ backgroundColor: "rgb(36, 77, 97)" }} variant="filled">
          organisation details
        </Button>
        <div>
          <Button
            onClick={(e) => setBuModal(true)}
            className="mr-4"
            style={{ backgroundColor: "rgb(86, 137, 192)" }}
            variant="filled"
          >
            add business unit
          </Button>
        </div>
      </div>

      <div className="mt-4">
        {!loading && list !== null && (
          <>
          <Table striped>
            <thead className="text-center">
              <tr>
                <th style={{ textAlign: "center" }}>bu id</th>
                <th style={{ textAlign: "center" }}>business unit name</th>
                <th style={{ textAlign: "center" }}>enabled?</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {list.map((row) => (
                <tr key={row.id}>
                  <td className="text-center">{row.id}</td>
                  <td className="text-center">{row.name}</td>
                  <td className="text-center">
                    <Badge color={row.enabled ? "indigo" : "red"}>
                      {row.enabled ? "yes" : "no"}
                    </Badge>
                  </td>
                  <td className="text-center">
                    <ActionIcon
                      color="white"
                      onClick={(e) => {
                        setEditBuId(row.id);
                        setBuModal(true);
                      }}
                    >
                      <IconEdit size={24} />
                    </ActionIcon>
                  </td>
                  <td className="text-center">
                    <ActionIcon
                      color="white"
                      onClick={(e) => navigate(`/viewbu/${row.id}`)}
                    >
                      <IconEye size={24} />
                    </ActionIcon>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="flex flex-row justify-center my-4">
          <Pagination
            page={page}
            onChange={(page) => {
              setPage(page);
            }}
            total={Math.ceil(count / 10)}
          />
        </div>
        </>
        )}
      </div>
    </div>
  );
};

export default TeamManager;
