import React, { useState } from "react";
import { loginUser } from "../apis/userApis";
import { useNavigate } from "react-router-dom";
import { Button, PasswordInput, TextInput } from "@mantine/core";
import {
  IconAt,
  IconLock,
  IconLockOff,
  IconLockOpen,
} from "@tabler/icons-react";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const loginUserFunction = async (e) => {
    e.preventDefault();

    if (email.length > 0 && password.length > 0) {
      try {
        const response = await loginUser({ email, password });

        if (response.success) {
          const user = {...response.user, "loginAs" :response.user.role.split(",")[0] }
          localStorage.setItem("userData", JSON.stringify(user));
          window.location.reload();
          navigate("/dashboard");
        } else {
          alert("Wrong ID or Password");
        }
      } catch (error) {
        alert("An error occurred during login. Please try again.");
      }
    } else {
      alert("Please enter both email and password.");
    }
  };

  return (
    <>
      <div class="min-h-screen flex items-center justify-center w-full dark:bg-gray-950">
        <div className="flex w-full h-full justify-center items-center">
          <form onSubmit={(e) => loginUserFunction(e)}>
            <div className="flex flex-col w-80">
              <img
                className="h-10"
                style={{ width: "12rem" }}
                src="https://dev.truecounsel.in/_next/image?url=%2Fassets%2Fnewlogo.png&w=384&q=75"
                alt="TrueCounsel"
              />
              <TextInput
                label="Email "
                type="email"
                id="email"
                className="my-2"
                placeholder="Enter Email"
                value={email}
                icon={<IconAt size={14} />}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                required
              />
            </div>
            <div class="mb-4">
              <PasswordInput
                label="Password"
                placeholder="Enter password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                icon={<IconLockOpen size={14} />}
                required
              />
            </div>

            <Button type="submit" className="my-2 w-full">
              Login
            </Button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
