import { Button, MultiSelect, Radio, Select, TextInput } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { addUser, updateUser } from "../apis/userApis";
import { useDispatch, useSelector } from "react-redux";
import { showNotification } from "@mantine/notifications";

const AddUserModal = ({ reload, dataToUpdate, buId, setUserModal }) => {
  const [userData, setUserData] = useState(
    dataToUpdate
      ? dataToUpdate
      : {
          name: "",
          email: "",
          enabled: true,
          department: 0,
          role: "",
          password: "",
          businessUnit: buId,
        }
  );
  console.log(userData);

  const [errors, setErrors] = useState({});
  const rolesRequiringDepartment = [
    "Ob-DeptAdmin",
    "Ob-DeptUser",
    "Ob-CXO",
    "Ob-Finance",
  ];

  const validateForm = () => {
    const newErrors = {};

    // Check for empty fields
    if (!userData.name) newErrors.name = "User name is required";
    if (!userData.email) newErrors.email = "Email is required";
    if (!userData.id && !userData.password)
      newErrors.password = "Password is required";
    if (!userData.role) newErrors.role = "User role is required";

    // Role-based validation for department

    if (
      userData.role
        .split(",")
        .some((role) => rolesRequiringDepartment.includes(role)) &&
      !userData.department
    ) {
      newErrors.department = "Department is required for this role";
    }

    // Check for user permission (optional if not mandatory)
    if (userData.enabled === undefined) {
      newErrors.enabled = "User permission is required";
    }

    setErrors(newErrors);

    // Return whether the form is valid
    return Object.keys(newErrors).length === 0;
  };

  const addServiceProvider = async (e) => {
    if (validateForm()) {
      e.preventDefault();
      try {
        const response = await addUser({
          name: userData.name,
          email: userData.email,
          role: userData.role,
          department: userData.department,
          password: userData.password,
          enabled: userData.enabled,
          businessUnit: parseInt(userData.businessUnit),
        });
        if (response.success) {
          setUserModal(false);

          reload();
        }
      } catch (error) {
        alert("error adding user");
      }
    }
  };

  const updateUserDetails = async () => {
    if (validateForm()) {
      const response = await updateUser({ userData });
      if (response.success) {
        showNotification({
          color: "green",
          title: "update user details",
          message: "user details updated ",
        });
        setUserModal(false);
        reload();
      }
    }
  };

  console.log("userData: ", userData);
  return (
    <>
      <div className="grid grid-cols-2 m-4 gap-4">
        <TextInput
          required
          label="User Name"
          type="text"
          value={userData.name}
          onChange={(e) => setUserData({ ...userData, name: e.target.value })}
          error={errors.name}
        />
        <TextInput
          required
          label="Email"
          type="email"
          value={userData.email}
          onChange={(e) => setUserData({ ...userData, email: e.target.value })}
          error={errors.email}
        />
        {!userData.id && (
          <TextInput
            required
            label="Password"
            type="text"
            value={userData.password}
            onChange={(e) =>
              setUserData({ ...userData, password: e.target.value })
            }
            error={errors.password}
          />
        )}
        <MultiSelect
          label="User Role"
          required
          placeholder="Select user role"
          value={userData.role.split(",")}
          onChange={(val) => setUserData({ ...userData, role: val.join(",") })}
          data={[
            { label: "admin", value: "Ob-Admin" },
            { label: "CEO", value: "Ob-CEO" },
            { label: "editor", value: "Ob-Editor" },
            // { label: "agreement-editor", value: "Ag-Editor" },
            { label: "COO", value: "Ob-COO" },
            { label: "CXO", value: "Ob-CXO" },
            { label: "deptadmin", value: "Ob-DeptAdmin" },
            { label: "deptuser", value: "Ob-DeptUser" },
            { label: "auditor", value: "Ob-Auditor" },
          ]}
          error={errors.role}
        />
        {userData.role
          .split(",")
          .some((role) => rolesRequiringDepartment.includes(role)) && (
          <Select
            label="Department"
            required
            value={userData.department}
            onChange={(val) => setUserData({ ...userData, department: val })}
            data={[
              { label: "FINANCE", value: 1 },
              { label: "HR", value: 2 },
              { label: "IT", value: 3 },
              { label: "TAXATION", value: 4 },
              { label: "MARKETING", value: 5 },
              { label: "ACCESSORIES", value: 6 },
              { label: "SPARES", value: 7 },
              { label: "OPERATIONS", value: 8 },
            ]}
            error={errors.department}
          />
        )}
        <Radio.Group
          label="User Permission"
          spacing="xs"
          withAsterisk
          value={userData.enabled + ""}
          onChange={(val) =>
            setUserData({ ...userData, enabled: val === "true" })
          }
          error={errors.enabled}
        >
          <Radio value="true" label="Enabled" />
          <Radio value="false" label="Disabled" />
        </Radio.Group>

        <div className="flex justify-end">
          {userData.id ? (
            <Button className="mt-4" onClick={(e) => updateUserDetails(e)}>
              update user
            </Button>
          ) : (
            <Button className="mt-4" onClick={(e) => addServiceProvider(e)}>
              add user
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default AddUserModal;
